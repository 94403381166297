//Store some state variables.
import { useState } from 'react';
//connect to our infura endpoint
import { providers, ethers } from 'ethers';
//check if metamask is installed in the browser.
import detectEthereumProvider from '@metamask/detect-provider';
import { Theme, SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
// import { provider } from './your/provider'
// Recommended to provide your own JSON-RPC endpoints
const jsonRpcUrlMap = {
  1: ['https://mainnet.infura.io/v3/b02fa7c04bbf4d39b20c69fe71d5ca94'],
  3: ['https://ropsten.infura.io/v3/b02fa7c04bbf4d39b20c69fe71d5ca94']
}
let darkMode = true // Dynamically toggle dark mode on and off
// Define a custom theme to override default attributes
const theme: Theme = {
  borderRadius: 12,
  fontFamily: '"Helvetica"',
  accent: '#FF007A',
  primary: '#000000',
  secondary: '#565A69',
}
function App() {
  const infuraId = process.env.REACT_APP_INFURA_ID;
  const jsonRpcEndpoint = `https://mainnet.infura.io/v3/b02fa7c04bbf4d39b20c69fe71d5ca94`;
  const jsonRpcProvider = new providers.JsonRpcProvider(jsonRpcEndpoint);
  const provider = new ethers.providers.Web3Provider(jsonRpcProvider);
  const [account, setAccount] = useState({
    address: '',
    provider: provider,
  })
  async function connectWallet() {
    //check if Metamask is installed in the browser
    const ethereumProvider = await detectEthereumProvider();
    if (ethereumProvider) {
      //prompt user to connect their wallet
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      const address = accounts[0];
      setAccount({
        address: address,
        provider: ethereumProvider
      })
    }
  }
  const MY_TOKEN_LIST = [
    {
      "name": "SMTX",
      "address": "0xDFEeF11Efe63e4FA7B2260D57423A848Af4b17eD",
      "symbol": "SMTX",
      "decimals": 18,
      "chainId": 3,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
    },
    {
      "name": "DAI",
      "address": "0xaD6D458402F60fD3Bd25163575031ACDce07538D",
      "symbol": "DAI",
      "decimals": 18,
      "chainId": 3,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
    }
  ]
  return (
    <div className="App" style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
      <div className="Uniswap">
        <SwapWidget
          theme={theme}
          defaultInputTokenAddress={'0xaD6D458402F60fD3Bd25163575031ACDce07538D'}
          defaultInputAmount={2}
          provider={account.provider}
          tokenList={MY_TOKEN_LIST}
          JsonRpcEndpoint={jsonRpcEndpoint} />
      </div>
    </div>
  );
}
export default App